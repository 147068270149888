<template>
    <div class="announcements" style="align-items: center;">
        <div class="announcements-list">
          <div class="announcements-list-announcement">
            {{ mostRecentQuestionnaire.portalMessage }}
          </div>
        </div>
        <router-link v-if="!mostRecentQuestionnaire.isStarted" :to="{ name: 'annual-questionnaire-client', params: { questionnaire: mostRecentQuestionnaire } }" class="announcements-close" style="width: 250px; color: white;">
          Go to Questionnaire <span><i class="fa fa-play" style="color: white;"></i></span>
        </router-link>
        <router-link v-else :to="{ name: 'annual-questionnaire-client', params: { questionnaire: mostRecentQuestionnaire } }" class="announcements-close" style="width: 250px; color: white;">
          Finish Annual BOLI Questionnaire <span><i class="fa fa-play" style="color: white;"></i></span>
        </router-link>
    </div>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'AnnualQuestionnaireAlert',
  props: {
    questionnaires: {
      type: Array,
      required: true
    }
  },
  computed: {
    mostRecentQuestionnaire () {
      return (_.sortBy(this.questionnaires, 'reportingPeriod').reverse())[0]
    }
  }
}
</script>
