
<script>
import { Bar } from 'vue-chartjs'
import ChartDataLabels from 'chartjs-plugin-datalabels'

export default {
  name: 'CreditConcentrationChart',
  extends: Bar,
  props: {
    chartdata: {
      type: Object,
      default: null
    },
    showCode: {
      type: Boolean,
      default: true
    },
    options: {
      type: Object,
      default: null
    },
    value: Object
  },
  watch: {
    'chartdata.timestamp': {
      deep: true,
      handler () {
        this.setup()
      }
    }
  },
  mounted () {
    this.setup()
  },
  methods: {
    setup () {
      let that = this
      this.addPlugin(ChartDataLabels)

      let dataSet = { ...this.chartdata }
      let labels = []

      dataSet.labels.map(_label => {
        if (that.showCode) {
          labels.push(_label.code)
        } else {
          labels.push(_label.name)
        }
      })

      dataSet.labels = labels

      // Create the chart
      this.renderChart(dataSet, this.options)
    }
  }
}
</script>
