
<script>
import { Doughnut } from 'vue-chartjs'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { mapGetters } from 'vuex'
import { activeLayout } from '../../vuex-actions'

export default {
  name: 'DoughnutChart',
  extends: Doughnut,
  props: {
    site: {
      type: Number,
      default: () => {
        return 1
      }
    },
    divisor: {
      type: Number,
      default: () => {
        return 1
      }
    },
    chartdata: {
      type: Object,
      default: null
    },
    isExpanded: {
      type: Boolean,
      default () {
        return false
      },
      required: false
    },
    options: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      gradient: null
    }
  },
  computed: {
    ...mapGetters([activeLayout])
  },
  mounted () {
    this.addPlugin(ChartDataLabels)

    window.addEventListener('resize', this.resize())
    let outOfBounds = false

    // Fill the center with color.
    this.addPlugin({
      id: 'fillCenter',
      afterDraw: (chart) => {
        let context = chart.chart.boxes[0].ctx
        const centerX = chart.width / 2
        const centerY = chart.height / 2

        // console.log(chart)

        outOfBounds = (chart.width / chart.height > 2) && !this.isExpanded

        context.beginPath()
        context.arc(centerX, centerY + 5, chart.innerRadius, 0, 2 * Math.PI)
        context.fillStyle = '#1d335e'
        context.fill()

        context.font = this.isExpanded ? '16px Arial' : outOfBounds ? '10px Arial' : '12px Arial'
        context.fillStyle = 'white'
        context.textAlign = 'center'
        context.fillText(this.site === 1 ? `${outOfBounds ? 'Allowable' : 'Allowable Capital'} ` : 'Net Worth', centerX, centerY - (this.isExpanded ? 5 : 2.5))

        context.font = this.isExpanded ? '1.5rem Arial' : '1rem Arial'
        context.fillStyle = 'white'
        context.textAlign = 'center'

        let total = (chart.config.data.datasets[0].data.reduce((a, b) => a + b, 0) * -1) / this.divisor

        if (chart.config.data.datasets[0].data[1] === 100 && chart.config.data.datasets[0].data[0] === 0) {
          total = parseInt(chart.config.data.datasets[0].labels[2].replace(',', ''))
        }

        context.fillText(
          total < 0 ? '$0' : '$' + (total).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
          centerX,
          centerY + (this.isExpanded ? 25 : 12)
        )
      }
    })

    this.options.plugins.datalabels.font.size = this.isExpanded ? '16' : outOfBounds ? '8' : '12'

    // Create the chart
    this.renderChart(this.chartdata, this.options)

    this.resize()
  },
  methods: {
    resize () {
      // Resize hook if needed

      // console.log(this.options, this.$parent)
    }
  }
}
</script>
